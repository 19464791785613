import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertBlock, IconError, Link, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Modal } from '@klarna/mp-ui';
import { createStorage } from 'mage-common';
import { selectors as configSelectors } from 'mage-config';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import mageInsights from 'mage-insights';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { enableMFA, getMFAShownAt, getMFAState, MFAState, setMFAShownAt } from './actions';
const ONE_DAY_IN_MS = 60 * 60 * 24 * 1000;
const MultiFactorModal = ({ isModalOpen = false, loadPage = url => window.location.assign(url), raiseAnalyticsEvent = mageInsights.event, analyticsCategory, onClose = () => { } }) => {
    const [isOpen, setIsOpen] = React.useState(isModalOpen);
    const createBackendClient = useSelector(coreSelectors.createBackendClient);
    const emberClient = createBackendClient('users');
    const tokenHelper = coreHooks.useTokenHelper();
    const userId = tokenHelper.getUserId();
    const localStorage = createStorage();
    const requester = useSelector(coreSelectors.getRequester);
    const isMfaEnabled = useSelector(coreSelectors.isRequesterMfaEnabled);
    const merchantPortalBaseUrl = useSelector(configSelectors.getMerchantPortalBaseUrl);
    useEffect(() => {
        const shownAt = getMFAShownAt(localStorage);
        if (!shownAt || isModalOpen) {
            setIsOpen(true);
        }
        else {
            const currentTime = Date.now();
            const elaspsedTime = currentTime - shownAt;
            if (elaspsedTime > ONE_DAY_IN_MS) {
                setIsOpen(true);
                raiseAnalyticsEvent({
                    category: '2fa-modal',
                    action: 'shown-automatically'
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage, getMFAShownAt, isModalOpen]);
    const handleOnClose = () => {
        setIsOpen(false);
        setMFAShownAt(localStorage);
        raiseAnalyticsEvent({
            category: analyticsCategory,
            action: 'close-modal'
        });
        onClose();
    };
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (analyticsCategory) {
            raiseAnalyticsEvent({
                category: analyticsCategory,
                action: 'start-setup'
            });
        }
        yield enableMFA(emberClient, userId);
        return loadPage(merchantPortalBaseUrl);
    });
    const t = i18nHooks.useTranslator();
    return isOpen && getMFAState(requester, isMfaEnabled) === MFAState.DISABLED ? (_jsxs(Modal, { onClose: handleOnClose, title: t('core.two-factor-opt-in.title'), description: t('core.two-factor-opt-in.intro-paragraph', {
            b: chunks => _jsx(Typography, { textToken: "text-style/text/labels/body/bold", children: chunks }),
            faqLink: chunks => (_jsx(Link, { href: `${merchantPortalBaseUrl}/two-factor-authentication`, children: chunks }))
        }), buttonPrimary: {
            onClick,
            text: t('core.two-factor-opt-in.continue-button'),
            'data-testid': 'home-mfa-opt-in-continue-button'
        }, useWindowHeight: true, children: [_jsxs(Typography, { textToken: "text-style/text/paragraphs/body/regular", children: [_jsx(Link, { textToken: "text-style/text/paragraphs/body/medium", isUnderlined: true, href: `${merchantPortalBaseUrl}/two-factor-authentication`, target: "two-factor-guide", children: t('core.two-factor-opt-in.help-link-text') }), t('core.two-factor-opt-in.help-text')] }), _jsx(SpacerVertical, { spaceToken: "space/400" }), _jsx(AlertBlock, { content: [t('core.two-factor-opt-in.warning-text')], iconType: () => _jsx(IconError, {}) })] })) : null;
};
MultiFactorModal.propTypes = {
    analyticsCategory: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool,
    loadPage: PropTypes.func,
    raiseAnalyticsEvent: PropTypes.func,
    onClose: PropTypes.func
};
export { MultiFactorModal };
